<template>
  <div class="chat">
    <div v-if="!chatStarted" class="chat-new">
      <p class="chat-new-header">
        PortfolioGPT helps you answer any questions on your offerings, cases and
        inspirational content in seconds
      </p>
      <p class="chat-new-subheader">
        <img
          src="@/assets/icons/ai.svg"
          alt=""
          class="chat-new-subheader-icon"
        />
        Try these examples
      </p>
      <div class="chat-new-examples">
        <div
          v-for="(example, idx) in exampleQuestions"
          :key="idx"
          class="chat-new-examples-item"
          @click="handleExampleClick(example)"
        >
          "{{ example }}"
        </div>
      </div>
    </div>
    <div v-else-if="historyLoading" class="chat-loading">
      <b-loading :is-full-page="false" active></b-loading>
    </div>
    <ul v-else ref="chatlog" class="chat-log">
      <li v-if="answerLoading" class="chat-log-item answer">
        <img :src="avatarByAuthor.bot" alt="" class="chat-log-item-avatar" />
        <LoadingDots class="chat-log-item-loading" color="white" />
      </li>
      <li
        v-for="(item, idx) in history"
        :key="`${item.author}-${idx}`"
        class="chat-log-item"
        :class="{
          question: item.author.toLowerCase() === 'user',
          answer: item.author.toLowerCase() === 'bot',
          content: item.author.toLowerCase() === 'bot-source'
        }"
      >
        <img
          v-if="avatarByAuthor[item.author.toLowerCase()] && !avatarErrored"
          :src="avatarByAuthor[item.author.toLowerCase()]"
          alt=""
          class="chat-log-item-avatar"
          @error="avatarErrored = true"
        />
        <div
          v-else
          class="chat-log-item-avatar"
          :style="{ background: $umodel.user_color(currentUser) }"
        >
          {{ $umodel.initials(currentUser) }}
        </div>
        <span
          v-if="item.author.toLowerCase() === 'user'"
          class="chat-log-item-text"
          >{{ item.content }}</span
        >
        <MarkdownEdit
          v-else-if="item.author.toLowerCase() === 'bot'"
          :value="item.content"
          class="chat-log-item-text answer"
        />
        <div v-else class="chat-log-item-cards">
          <component
            :is="cardComponent[item.type]"
            v-for="el in item.content"
            :key="el.uuid"
            :clickable="true"
            v-bind="cardProp(item.type, el)"
            class="chat-log-item-cards-card"
          />
        </div>
        <span class="chat-log-item-timestamp">{{
          formatDateWTime(item.timestamp)
        }}</span>
      </li>
    </ul>
    <div class="chat-input">
      <span
        ref="questioninput"
        role="textbox"
        :contenteditable="!(answerLoading || answerTyping || historyLoading)"
        placeholder="Ask anything about your offerings, cases or inspirational content..."
        class="chat-input-input is-textarea"
        @input="handleQuestionInput"
        @paste.prevent="handleQuestionPaste"
        @keydown.enter="handleEnter"
      ></span>
      <img
        src="@/assets/icons/send.svg"
        alt=""
        class="chat-input-icon"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import { askQuestion, getChatHistory } from '@/services/chatService'
import { formatDateWTime } from '@/util'
import LoadingDots from '@c/library/LoadingDots.vue'
import { mapActions, mapGetters } from 'vuex'
import InspirationCard from '../inspirations/InspirationCard.vue'
import OfferingCard from '../offerings/OfferingCard.vue'
import ReferenceCard from '../references/ReferenceCard.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'

export default {
  name: 'ChatInterface',
  components: { LoadingDots, MarkdownEdit },
  props: {
    chatUuid: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    question: '',
    chatStarted: false,
    chatId: '',
    history: [],
    historyLoading: false,
    avatarErrored: false,
    answerLoading: false,
    answerTyping: false,
    seenSources: []
  }),
  computed: {
    ...mapGetters(['currentUser']),
    avatarByAuthor() {
      return {
        user: this.currentUser.avatar,
        bot: require('@/assets/logo.svg'),
        'bot-source': require('@/assets/logo.svg')
      }
    },
    exampleQuestions() {
      return [
        'I need a brief, high-level overview of our offerings.',
        'For which industries do we have cases?',
        'I feel curious today, teach me about one of our offerings'
      ]
    },
    cardComponent() {
      return {
        reference_ids: ReferenceCard,
        offering_ids: OfferingCard,
        inspiration_ids: InspirationCard
      }
    }
  },
  mounted() {
    this.$refs.questioninput.focus()
    if (this.chatUuid) {
      this.chatId = this.chatUuid
      this.chatStarted = true
      this.loadHistory()
    }
  },
  methods: {
    ...mapActions([
      'getReferenceInfo',
      'getOfferingInfo',
      'getInspirationInfo'
    ]),
    handleEnter(e) {
      if (e.shiftKey) return
      e.preventDefault()
      this.sendMessage(e)
    },
    handleQuestionInput(e) {
      this.question = e.target.innerText
    },
    handleQuestionPaste(e) {
      let paste = (e.clipboardData || window.clipboardData).getData(
        'text/plain'
      )
      this.question = paste
      const selection = window.getSelection()
      if (!selection.rangeCount) return
      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(paste))
      selection.collapseToEnd()
    },
    sendMessage() {
      const q = this.question.trim()
      if (q) {
        this.history.unshift({
          author: 'user',
          content: q,
          timestamp: new Date()
        })
        this.$refs.questioninput.innerText = ''
        this.$refs.questioninput.blur()
        this.question = ''
        this.getAnswer()
      }
    },
    async getAnswer() {
      this.answerLoading = true

      let data = undefined
      let hasSources = false

      try {
        if (!this.chatStarted) this.chatStarted = true
        this.answerLoading = true
        data = await askQuestion({
          workspace_id: this.$route.params.workspace_id,
          question: this.history[0].content,
          act_as: this.$route.query.act_as,
          ...(this.chatId && { chat_id: this.chatId })
        })
        this.chatId = data.uuid
        this.history.unshift({
          author: 'bot',
          id: data.id,
          content: data.content,
          timestamp: data.timestamp
        })

        hasSources = Object.keys(data.sources || {}).some(
          source =>
            data.sources[source].length &&
            !data.sources[source].every(s => this.seenSources.includes(s))
        )
      } catch (e) {
        this.$console.debug('Chat question failed', e)
        this.history.unshift({
          author: 'bot',
          id: 'error',
          content:
            'An error occured while trying to ask a question. Please try again later or contact support.',
          timestamp: new Date()
        })
      }

      if (hasSources) {
        try {
          for (const source in data.sources || {}) {
            if (data.sources[source].every(s => this.seenSources.includes(s))) {
              continue
            }
            const getInfo = {
              reference_ids: this.getReferenceInfo,
              offering_ids: this.getOfferingInfo,
              inspiration_ids: this.getInspirationInfo
            }[source]

            let content = await Promise.all(
              (data.sources[source] || [])
                .filter(s => !this.seenSources.includes(s))
                .map(id =>
                  getInfo({
                    workspace_id: this.$route.params.workspace_id,
                    ori_id: id,
                    act_as: this.$route.query.act_as
                  })
                )
            )

            if (content?.length) {
              this.seenSources = [
                ...this.seenSources,
                ...content.map(r => r.uuid)
              ]
              this.history.unshift({
                author: 'bot-source',
                content,
                type: source,
                id: data.id,
                timestamp: data.timestamp
              })
            }
          }
        } catch (e) {
          this.$console.debug('Chat sources load failed', e)
          this.history.unshift({
            author: 'bot',
            id: 'error',
            content:
              'An error occured while trying to visualise the relevant content. Please try again later or contact support.',
            timestamp: new Date()
          })
        }
      }

      this.answerLoading = false

      this.$nextTick(() => {
        this.$refs.questioninput.focus()
        this.$refs.chatlog.scrollTop = this.$refs.chatlog.scrollHeight
        if (data.title) {
          this.$emit('update', {
            uuid: this.chatId,
            title: data.title
          })
        }
      })
    },
    handleExampleClick(example) {
      this.question = example
      this.sendMessage()
    },
    async loadHistory() {
      this.historyLoading = true
      try {
        const data = await getChatHistory({
          workspace_id: this.$route.params.workspace_id,
          chat_id: this.chatUuid,
          act_as: this.$route.query.act_as
        })
        let toReturn = []
        let messages = data.messages
        messages.reverse()
        for (const message of messages || []) {
          const messageId = message.id
          const hasSources = Object.keys(message.sources || {}).some(
            source =>
              (message.sources?.[source] || []).length &&
              !(message.sources?.[source] || []).every(s =>
                this.seenSources.includes(s)
              )
          )
          if (hasSources || message.author.toLowerCase() === 'user') {
            delete message.id
          }
          toReturn = [...toReturn, message]
          for (const source in message.sources) {
            if (
              message.sources[source].every(s => this.seenSources.includes(s))
            ) {
              continue
            }
            const getInfo = {
              reference_ids: this.getReferenceInfo,
              offering_ids: this.getOfferingInfo,
              inspiration_ids: this.getInspirationInfo
            }[source]

            let content = await Promise.all(
              message.sources[source]
                .filter(s => !this.seenSources.includes(s))
                .map(id =>
                  getInfo({
                    workspace_id: this.$route.params.workspace_id,
                    ori_id: id,
                    act_as: this.$route.query.act_as
                  })
                )
            )
            if (content?.length) {
              this.seenSources = [
                ...this.seenSources,
                ...content.map(r => r.uuid)
              ]
              toReturn = [
                ...toReturn,
                {
                  author: 'bot-source',
                  content,
                  type: source,
                  id: messageId,
                  timestamp: message.timestamp
                }
              ]
            }
          }
        }
        toReturn.reverse()
        this.history = toReturn
      } catch (e) {
        this.$console.debug('Chat history fetch failed', e)
        this.history.unshift({
          author: 'bot',
          id: 'error',
          content:
            'An error occured while trying to load the chat history. Please try again later or contact support.',
          timestamp: new Date()
        })
      } finally {
        this.historyLoading = false
      }
    },
    formatDateWTime(date) {
      return formatDateWTime(date)
    },
    cardProp(type, el) {
      const keyMap = {
        reference_ids: 'reference',
        offering_ids: 'offering',
        inspiration_ids: 'inspiration'
      }
      return {
        [keyMap[type]]: el
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 2rem;

  &-new {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &-header {
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      max-width: 80%;
    }

    &-subheader {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      color: #60666b;
      max-width: 80%;

      &-icon {
        height: 1.2rem;
        filter: brightness(0) saturate(100%) invert(41%) sepia(14%)
          saturate(198%) hue-rotate(165deg) brightness(91%) contrast(93%);
      }
    }

    &-examples {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      &-item {
        flex: 1;
        height: 100%;
        padding: 1rem;
        border: 1px solid rgba(#000, 8%);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:hover {
          background: rgba(#000, 8%);
        }
      }
    }
  }

  &-loading {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &-log {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    display: flex;
    flex-flow: column-reverse nowrap;
    gap: 1rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      gap: 1rem;
      max-width: 65%;
      position: relative;

      &.question {
        align-self: flex-end;
        flex-flow: row-reverse nowrap;

        & > .chat-log-item-text {
          background: white;
          border: 1px solid rgba(#000, 8%);
          padding: 1rem;
        }

        & > .chat-log-item-timestamp {
          right: calc(100% + 0.5rem);
        }
      }

      &.answer {
        align-self: flex-start;

        & > .chat-log-item-text {
          background: #303032;
          color: white;
          padding: 0 1rem;
        }

        & > .chat-log-item-timestamp {
          left: calc(100% + 0.5rem);
        }
      }

      &.content {
        align-self: flex-start;

        & > .chat-log-item-cards {
          background: #303032;
          color: white;
        }

        & > .chat-log-item-timestamp {
          left: calc(100% + 0.5rem);
        }
      }

      &:hover {
        & > .chat-log-item-timestamp {
          opacity: 1;
        }
      }

      &-avatar {
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
        min-height: 2.5rem;
        border-radius: 999rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: white;
      }

      &-text {
        min-height: 2.2rem;
        color: inherit;
        border-radius: 16px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 8%);
        width: fit-content;
        line-height: 1.2rem;
      }

      &-cards {
        background: #303032;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        min-height: 2.2rem;
        border-radius: 16px;
        padding: 1rem;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 8%);
        width: fit-content;

        &-card {
          cursor: pointer;
          transition: transform 0.2s ease;

          &:hover {
            transform: scale(1.02);
          }
        }
      }

      &-timestamp {
        color: #60666b;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        white-space: nowrap;
        pointer-events: none;
      }

      &-loading {
        height: 2.2rem;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 8%);
        width: fit-content;
        background: #303032;
      }
    }
  }

  &-input {
    position: relative;
    margin: 0 5vw;

    &-input {
      padding: 0.25rem 0.5rem;
      background: #f1f2f3;
      border-radius: 4px;
      border: 1px solid rgba(#000, 8%);
      width: 100%;

      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        border: 1px solid $primary;
        background: white;
      }

      &.is-textarea {
        min-height: 2.2rem;
        display: block;
        max-height: 40vh;
        overflow: auto;
        white-space: pre-wrap;
        padding: 0.5rem 2.5rem 0.5rem 0.5rem;

        &:empty:not(:focus):before {
          content: attr(placeholder);
          pointer-events: none;
          display: block;
        }
      }
    }

    &-icon {
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
      transform: translateY(50%);
      height: 1.5rem;
      cursor: pointer;
      filter: invert(58%) sepia(95%) saturate(24%) hue-rotate(177deg)
        brightness(84%) contrast(84%);
    }
  }
}

::v-deep .markdown-edit-view-content {
  & * {
    color: white !important;
  }

  & code {
    background: #60666b;
  }
}
</style>
